export default class Structure {
  static fullName(structure) {
    if(structure.job_number)
      return `(${ structure.job_number }) ${ structure.name }`
    else
      return structure.name
  }

  static page(uri, callback, structures=[], page=1) {
    $.get(`${ uri }?page=${ page }`, (data) => {
      if(data.structures.length === 0)
        callback(structures)
      else {
        structures = [...structures, ...data.structures]
        this.page(uri, callback, structures, page + 1)
      }
    })
  }

  static loadChildren(structure, callback) {
    this.page(`/api/v4/structures/${ structure.id }/structures.json`, (structures) => {
      callback(this.sortByName(structures))
    })
  }

  static sortByName(structures) {
    return structures.sort((a, b) => {
      let nameA = this.fullName(a).toLowerCase()
      let nameB = this.fullName(b).toLowerCase()

      if(nameA < nameB)
        return -1
      else if(nameA > nameB)
        return 1
      else
        return 0
    })
  }

  static getChildCounts(structurePaths, rootId) {
    let activeChildPaths, childCounts = {}

    if(rootId)
      activeChildPaths = structurePaths.filter(path => {
        return path.includes(rootId) && path[path.length - 1] !== rootId
      })
    else
      activeChildPaths = structurePaths

    activeChildPaths.forEach(path => {
      let childId

      if(rootId) {
        let index = path.findIndex(id => id === rootId)
        childId = path[index + 1]
      } else
        childId = path[0]

      childCounts[childId] = (childCounts[childId] || 0) + 1
    })

    return childCounts
  }
}
