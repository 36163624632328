import { combineReducers } from "redux"

import {
  DELETE_INSPECTION_FORM_STRUCTURE,
  SELECT_INSPECTION_FORM_STRUCTURE,
  ADD_INSPECTION_FORMS_STRUCTURES
} from '../actions/inspectionFormsStructuresActions'
import { SELECT_STRUCTURE } from '../actions/structuresActions'
import { createReducer } from './index'


// State Reducer
const initialState = {
  selectedId: undefined,
}

const state = (state = initialState, action) => {
  switch(action.type) {
    case SELECT_INSPECTION_FORM_STRUCTURE:
      return {...state, selectedId: action.id}
    case SELECT_STRUCTURE:
      return {...state, selectedId: undefined }
    default:
      return state
  }
}


// Inspection Forms Manager
function addInspectionFormsStructures(state, action) {
  let nextState = { ...state }
  action.objects.forEach(ifs => {
    nextState[ifs.id] = ifs
  })
  return nextState
}

function deleteInspectionFormStructure(state, action) {
  let newState = { ...state }
  delete newState[action.inspectionFormStructureId]
  return newState
}

const inspectionFormsStructuresById = createReducer({}, {
  DELETE_INSPECTION_FORM_STRUCTURE: deleteInspectionFormStructure,
  ADD_INSPECTION_FORMS_STRUCTURES: addInspectionFormsStructures
})

// Combined reducer
const inspectionFormsStructures = combineReducers({
  state,
  inspectionFormsStructuresById
})
export default inspectionFormsStructures
