import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructure } from "./store/actions/structuresActions";

// Helpful to understand refs
// https://reactjs.org/docs/refs-and-the-dom.html#callback-refs

class AddStructureInlineFormBase extends React.Component {
  static propTypes = {
    parentId: PropTypes.number.isRequired,
    handleAddStructureClick: PropTypes.func.isRequired,
    handleCancelClick: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.nameInput = null;
    this.setStructureNameInputRef = (element) => {
      this.nameInput = element;
    };
    this.focusInput = () => {
      if (this.nameInput) this.nameInput.focus();
    };
    this.state = {
      showError: false,
    };
  }

  componentDidMount() {
    this.focusInput();
  }

  submit = (e) => {
    e.preventDefault();
    const name = this.nameInput.value;
    if (name.length > 0) {
      this.props.handleAddStructureClick(name, this.props.parentId);
      this.nameInput.value = "";
      this.setState({ showError: false });
    } else {
      this.setState({ showError: true });
    }
    this.focusInput();
  };

  cancel = () => {
    this.props.handleCancelClick(this.props.parentId);
  };

  render() {
    return (
      <ul>
        <li>
          <div
            className="form-inline"
            onKeyPress={(e) => {
              if (e.key === "Enter") this.submit(e);
            }}
          >
            <div
              className={
                (this.state.showError ? "has-error " : "") + "form-group"
              }
            >
              <i className="fas fa-folder-open right-margin"></i>
              <input
                type="text"
                className="form-control"
                ref={this.setStructureNameInputRef}
              />

              <input
                type="submit"
                value="Save"
                className="btn btn-default left-margin"
                onClick={this.submit}
              />

              <a className="left-margin" onClick={this.cancel}>
                Cancel
              </a>
            </div>
          </div>
        </li>
      </ul>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleAddStructureClick: (name, parentId) => {
      dispatch(createStructure(name, parentId));
    },
  };
};

const AddStructureInlineForm = connect(
  undefined,
  mapDispatchToProps
)(AddStructureInlineFormBase);

export default AddStructureInlineForm;
