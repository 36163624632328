import React from "react"
import ReactAutocomplete from "react-autocomplete"
import PropTypes from "prop-types"
import "./style.scss"

export default class Autocomplete extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    placeholder: PropTypes.string,
    minChar: PropTypes.number
  }

  static defaultProps = {
    onChange: () => {},
    onSelect: () => {},
    placeholder: "",
    value: "",
    minChar: 1
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  shouldItemRender(item, value) {
    return (
      value !== "" &&
      value.length >= this.props.minChar &&
      item.label.match(new RegExp(this.escapeRegExp(value), "i"))
    )
  }

  render() {
    let inputProps = {
      className: "form-control",
      placeholder: this.props.placeholder
    }

    let renderMenu = (items, value, style) =>
        <ul className="list-group" style={{ minWidth: style.minWidth, position: "absolute" }} children={ items } />

    let renderItem = (item, isHighlighted) =>
        <div className="list-group-item" key={ item.label } style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
          { item.label }
        </div>

    return <div className="Autocomplete">
      <ReactAutocomplete
        getItemValue={ item => item.label }
        items={ this.props.items }
        renderMenu={ renderMenu }
        shouldItemRender={ (item, value) => this.shouldItemRender(item, value) }
        wrapperStyle={{ position: "relative", display: "inline-block", width: "100%" }}
        renderItem={ renderItem }
        onChange={ this.props.onChange }
        onSelect={ this.props.onSelect }
        value={ this.props.value || "" }
        inputProps={ inputProps }
      />
    </div>
  }
}
