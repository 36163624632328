import React from "react"
import PropTypes from "prop-types"

import Menu from "../Menu"
import Autocomplete from "../../Autocomplete"
import { LineItemDND } from "../../../lib/DragAndDrop"

import "./style.scss"

class LineItem extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    onAdd: PropTypes.func,
    onDelete: PropTypes.func,
    onInsert: PropTypes.func,
    onMove: PropTypes.func,
    lineItem: PropTypes.object,
    ratings: PropTypes.object,
    lineItems: PropTypes.array,
    nested: PropTypes.bool
  }

  static defaultProps = {
    onChange: () => {},
    onAdd: () => {},
    onDelete: () => {},
    onInsert: () => {},
    onMove: () =>  {},
    lineItems: [],
    nested: false
  }

  constructor() {
    super()
    this.state = { expanded: false }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.lineItem !== this.props.lineItem ||
      nextProps.isDragging !== this.props.isDragging ||
      nextState.expanded !== this.state.expanded
  }

  toggle(event) {
    if(event.target === event.currentTarget)
      this.setState({ expanded: !this.state.expanded })
  }

  update(newProps, event) {
    let lineItem = Object.assign({}, this.props.lineItem, newProps)

    if(!this.enableWeight(lineItem.ratingId))
      lineItem.weight = "0"

    if(!this.enableWeight(this.props.lineItem.ratingId) && this.enableWeight(lineItem.ratingId))
      lineItem.weight = "1"

    this.props.onChange(lineItem)
  }

  selectedRating(ratingId) {
    let flattenedRatings = []
    let groups = this.props.ratings.groups

    if(ratingId)
      ratingId = parseInt(ratingId)

    if(!Number.isInteger(ratingId))
      ratingId = this.props.lineItem.ratingId

    if(ratingId) {
      Object.keys(groups).forEach(name => flattenedRatings = flattenedRatings.concat(groups[name]))
      return flattenedRatings.find(rating => rating.id.toString() === ratingId.toString())
    }
  }

  enableWeight(ratingId) {
    let scoreableTypeIds = this.props.ratings.scoreableTypeIds
    let rating = this.selectedRating(ratingId)

    if(rating)
      return scoreableTypeIds.find(scoreableId => rating.rating_type_id === scoreableId)
    else
      return true
  }

  render() {
    let { connectDragSource, connectDropTarget, connectDragPreview, isDragging } = this.props
    let item = this.props.lineItem
    let style = isDragging ? { opacity: 0 } : {}

    let optionGroups = Object.keys(this.props.ratings.groups).map(group => {
      let options = this.props.ratings.groups[group].map(option => {
        return <option key={ option.id } value={ option.id }>{ option.name }</option>
      })

      return <optgroup key={ group } label={ group }>{ options }</optgroup>
    })

    let bodyClasses = "LineItem__body", toggleClasses = "far"
    if(this.state.expanded)
      toggleClasses += " fa-minus-square"
    else {
      bodyClasses += " LineItem__body--hidden"
      toggleClasses += " fa-plus-square"
    }

    return connectDragPreview(
      connectDropTarget(
        <div className="LineItem" key={ this.props.key } style={ style }>
          <div className="row">
            <div className="col-md-1">
              {
                connectDragSource(
                  <div>
                    <Menu>
                      <ul>
                        <li><a onClick={ () => this.props.onAdd() }>Add a line item</a></li>
                        <li><a onClick={ () => this.props.onInsert() }>Insert section</a></li>
                        <li><a onClick={ () => this.props.onDelete() }>Delete</a></li>
                      </ul>
                    </Menu>
                  </div>
                )
              }
            </div>

            <div className="col-md-5">
              <Autocomplete
                items={ this.props.lineItems.map(item => ({ label: item.name })) }
                value={ item.name }
                onChange={ event => this.update({ name: event.target.value }, event) }
                onSelect={ value => this.update({ name: value }) }
                placeholder="Line Item Name"
                minChar={ 3 }
              />
            </div>

            <div className="col-md-3">
              <select
                className="form-control"
                onChange={ event => this.update({ ratingId: event.target.value }) }
                value={ item.ratingId || "" }
              >
                { optionGroups }
              </select>
            </div>

            <div className="col-md-2">
              <input
                onChange={ event => this.update({ weight: event.target.value }) }
                value={ item.weight }
                type="number"
                min="0"
                className="form-control"
                disabled={ !this.enableWeight() }
              />
            </div>

            <div className="col-md-1">
              <i onClick={ event => this.toggle(event) } className={ toggleClasses }></i>
            </div>
          </div>

          <div className={ bodyClasses }>
            <textarea
              className="form-control"
              onChange={ event => this.update({ description: event.target.value }) }
              value={ item.description || "" }
              placeholder="Description"
            />
          </div>
        </div>
      )
    )
  }
}

export default LineItemDND(LineItem)
