import React from "react"
import PropTypes from "prop-types"
import TreeNode from "./TreeNode"

export default class Tree extends React.Component {
  static propTypes = {
    roots: PropTypes.array,
    renderChild: PropTypes.func
  }

  render() {
    let rootsPartial = this.props.roots.map(root => {
      return <li key={ root.key }>
        <TreeNode
          root={ root }
          renderChild={ this.props.renderChild }
          ancestry={ [] }
        />
      </li>
    })

    return <div className="Tree">
      <ul> { rootsPartial } </ul>
    </div>
  }
}
