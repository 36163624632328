$(function() {
  function toggleHiddenIntArray(form, hiddenFieldSelector, attrName) {
    return function(e) {
      const hiddenField = form.find(hiddenFieldSelector)

      const targetValue = e.target.value

      const fieldsWithValue = hiddenField.find(`[value="${targetValue}"]`)
      if (e.target.checked) {
        const newHiddenField = $('<input>').attr({
          type: 'hidden',
          name: `ticket_automation[${attrName}][]`,
          value: targetValue,
          'data-test-id': `${attrName.replace('_', '-')}-hidden-field`
        })
        hiddenField.append(newHiddenField)
      } else {
        fieldsWithValue.remove()
        e.target.checked = false
      }
    }
  }

  function disableThenActions(form) {
    return function(e) {
      if (e.target.checked) {
        form.find('[data-then-action-id]').attr('disabled', true)
        form.find('[data-then-action-input]').attr('disabled', true)
      } else {
        form.find('[data-then-action-id]').attr('disabled', false)
        form.find('[data-then-action-input]').attr('disabled', false)
      }
    }
  }

  const conditionalFilterForm = $('form[data-ticket-automation]')

  if (conditionalFilterForm.length > 0) {
    conditionalFilterForm.
      find('[data-if-condition-id]').
      click(toggleHiddenIntArray(conditionalFilterForm, '[data-if-conditions]', 'if_conditions'))

    conditionalFilterForm.
      find('[data-then-action-id]').
      click(toggleHiddenIntArray(conditionalFilterForm, '[data-then-actions]', 'then_actions'))

    conditionalFilterForm.
      find('[data-then-action-ignore-ticket]').
      change(disableThenActions(conditionalFilterForm))
  }
});
