import { combineReducers } from "redux";
import Structure from "../models/Structure";
import { createReducer } from "./index";
import {
  ADD_INSPECTION_FORMS_STRUCTURES_TO_STRUCTURE,
  ADD_STRUCTURES,
  SELECT_STRUCTURE,
  SET_TOP_LEVEL_STRUCTURE,
  DELETE_STRUCTURE,
  LOADING_RELATIONSHIPS_FOR_STRUCTURE,
  LOADED_RELATIONSHIPS_FOR_STRUCTURE,
  UPDATE_STRUCTURE,
} from "../actions/structuresActions";
import {
  ADD_INSPECTION_FORMS_STRUCTURES,
  DELETE_INSPECTION_FORM_STRUCTURE,
} from "../actions/inspectionFormsStructuresActions";

/**
 *
 * Example state for an individual structure:
 * {
 *   structure properties,
 *   inspectionFormsStructuresIds: [],
 *   childrenIds: [],
 *   showInlineForm: false // look at moving this to component state
 * }
 **/

/**
 * Helper functions
 */

function replaceStructure(state, structureId, props) {
  let newState = { ...state };
  newState[structureId] = new Structure({
    ...state[structureId],
    ...props,
  });
  return newState;
}

function removeIdFromList(id, list) {
  let newList = [...list];
  const index = newList.indexOf(id);
  newList.splice(index, 1);
  return newList;
}

/**
 * Structure state reducer
 */

const state = (state = { topStructureIds: [] }, action) => {
  switch (action.type) {
    case SET_TOP_LEVEL_STRUCTURE:
      let ids = [...state.topStructureIds];
      let selectedId = action.id;
      if (state.topStructureId !== action.id) {
        ids.push(state.topStructureId);
      } else if (ids.length > 0) {
        selectedId = ids.pop();
      }
      return {
        ...state,
        topStructureIds: ids,
        topStructureId: selectedId,
        selectedId: selectedId,
      };
    case SELECT_STRUCTURE:
      return { ...state, selectedId: action.id };
    case DELETE_STRUCTURE:
      return { ...state, selectedId: undefined };
    default:
      return state;
  }
};

/**
 * Action handlers for structures
 */

// Not guaranteed to be from the same structure
function addInspectionFormsToStructures(state, action) {
  let byStructureId = [];
  action.objects.forEach((ifs) => {
    const ids = byStructureId[ifs.structure_id];
    byStructureId[ifs.structure_id] = ids ? ids.concat(ifs.id) : [ifs.id];
  });

  let newState = { ...state };
  for (let id in byStructureId) {
    const newIds = byStructureId[id];
    const oldIds = state[id].inspectionFormsStructuresIds;
    const ids = [...new Set(newIds.concat(oldIds))];

    newState = replaceStructure(newState, id, {
      inspectionFormsStructuresIds: ids,
    });
  }

  return newState;
}

function removeInspectionFormStructureFromStructure(state, action) {
  const structureId = action.structureId;

  let list = removeIdFromList(
    action.inspectionFormStructureId,
    state[structureId].inspectionFormsStructuresIds
  );

  return replaceStructure(state, structureId, {
    inspectionFormsStructuresIds: list,
  });
}

function addStructures(state, action) {
  let nextState = { ...state };
  action.objects.forEach((structure) => {
    nextState[structure.id] = new Structure(structure);
  });
  // Add to parent
  const structureIds = action.objects.map((o) => o.id);
  let childrenIds = nextState[action.parentId].childrenIds;
  const newChildrenIds = [...new Set(childrenIds.concat(structureIds))];

  return replaceStructure(nextState, action.parentId, {
    childrenIds: newChildrenIds,
    active_children_count: newChildrenIds.length,
  });
}

function deleteStructure(state, action) {
  let nextState = { ...state };
  const parentId = nextState[action.id].structure_id;
  delete nextState[action.id];

  let childrenIds = removeIdFromList(action.id, state[parentId].childrenIds);

  return replaceStructure(nextState, parentId, {
    active_children_count: childrenIds.length,
    childrenIds: childrenIds,
  });
}

function updateStructure(state, action) {
  return replaceStructure(state, action.object.id, action.object);
}

function loadingRelationshipsForStructure(state, action) {
  return replaceStructure(state, action.id, {
    isLoadingRelationships: true,
  });
}

function loadedRelationshipsForStructure(state, action) {
  return replaceStructure(state, action.id, {
    isLoadingRelationships: false,
    hasLoadedRelationships: true,
  });
}

// Reducer for actions
const structuresById = createReducer(
  {},
  {
    DELETE_INSPECTION_FORM_STRUCTURE:
      removeInspectionFormStructureFromStructure,
    ADD_INSPECTION_FORMS_STRUCTURES: addInspectionFormsToStructures,
    ADD_STRUCTURES: addStructures,
    DELETE_STRUCTURE: deleteStructure,
    UPDATE_STRUCTURE: updateStructure,
    LOADING_RELATIONSHIPS_FOR_STRUCTURE: loadingRelationshipsForStructure,
    LOADED_RELATIONSHIPS_FOR_STRUCTURE: loadedRelationshipsForStructure,
  }
);

const structures = combineReducers({
  state,
  structuresById,
});
export default structures;
