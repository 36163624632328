import { combineReducers } from "redux"

import {
  FETCHING_INSPECTION_FORMS,
  FETCHED_INSPECTION_FORMS,
} from '../actions/inspectionFormsActions'

const initialState = {
  isFetching: false,
  isLoaded: false,
}

const state = (state = initialState, action) => {
  switch(action.type) {
    case FETCHING_INSPECTION_FORMS:
      return {...state, isFetching: true }
    case FETCHED_INSPECTION_FORMS:
      return {...state, isLoaded: true, isFetching: false }
    default:
      return state
  }
}

const inspectionFormsById = (state = {}, action) => {
  switch(action.type) {

    case FETCHED_INSPECTION_FORMS:
      let newState = {...state}
      action.objects.forEach( (form) => {
        newState[form.id] = form
      })
      return newState

    default:
      return state
  }
}

const inspectionForms = combineReducers({
  state,
  inspectionFormsById
})
export default inspectionForms
