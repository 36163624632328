export default class RightPane {
  static setBindings() {
    $('.js-datepicker').datepicker({ dateFormat: 'yy-mm-dd' })
    $('.js-right-pane-structure').on('ajax:success', this.updateAreaHandler)
    $('.js-right-pane-structure').on('ajax:error', this.updateAreaErrorHandler)
    $('.js-right-pane-archive').on('ajax:success', this.archiveAreaHandler)
    $('.select2').each(function (i, obj) {
      if ($(obj).data('select2') == undefined) {
        $(obj).select2()
      }
    });
  }

  static removeBindings() {
    $('.js-right-pane-structure').off('ajax:success', this.updateAreaHandler)
    $('.js-right-pane-structure').off('ajax:error', this.updateAreaErrorHandler)
    $('.js-right-pane-archive').off('ajax:success', this.archiveAreaHandler)
  }

  static updateAreaHandler(event, data, status, error) {
    $(this).find(".js-alert-box .js-alert-message").text("Area was successfully saved!");
    $(this).find(".js-alert-box .alert-success").show();

    let updateEvent = new CustomEvent("AreaTree:updateArea", { detail: data })
    document.dispatchEvent(updateEvent)
  }

  static updateAreaErrorHandler(event, xhr, status, error) {
    $(this).find(".js-alert-box .js-alert-message").text("There was an issue saving. Please check your input and try again.")
    $(this).find(".js-alert-box .alert-danger").show()
  }

  static archiveAreaHandler(event, data, status, error) {
    let archiveEvent = new CustomEvent("AreaTree:archiveArea", { detail: data })
    document.dispatchEvent(archiveEvent)
  }

}
