import { combineReducers } from "redux"
import structures from "./structuresReducer"
import inspectionForms from "./inspectionFormsReducer"
import inspectionFormsStructures from "./inspectionFormsStructuresReducer"

export default combineReducers({
  structures,
  inspectionForms,
  inspectionFormsStructures
})


export function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } else {
      return state
    }
  }
}
