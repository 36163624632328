import React from "react"
import PropTypes from "prop-types"
import SingleLocationPicker from "./SingleLocationPicker"
import LocationPreview from "./LocationPreview"
import "./style"

export default class LocationPicker extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    selected: PropTypes.oneOfType([ PropTypes.number, PropTypes.array ]),
    multiple: PropTypes.bool,
    preview: PropTypes.object,
    allowClear: PropTypes.bool,
    selectLocation: PropTypes.bool,
    required: PropTypes.bool
  }

  static defaultProps = {
    multiple: false,
    allowClear: true,
    selectLocation: false,
    required: false
  }

  constructor(props) {
    super(props)

    if(this.props.selected && this.props.selected.length && this.props.selected.length > 0) {
      let count = 1
      let newPickers = this.props.selected.map((s) => {
        let id = count
        count = count + 1

        return { id: id, selected: s }
      })

      this.state = { pickers: newPickers, count: this.props.selected.length }
    } else {
      let selected
      if(this.props.selected && !(this.props.selected.constructor === Array))
        selected = this.props.selected

      this.state = { pickers: [{ id: 1, selected: selected  }], count: 1 }
    }
  }

  addPicker() {
    let newCount = this.state.count + 1
    let newPickers = [...this.state.pickers, { id: newCount } ]

    this.setState({ pickers: newPickers, count: newCount })
  }

  removePicker(picker) {
    let newPickers = this.state.pickers.filter(p => p.id !== picker.id)
    this.setState({ pickers: newPickers })
  }

  className() {
    if(this.props.multiple)
      return "LocationPicker LocationPicker--multiple"
    else
      return "LocationPicker"
  }

  handleChange(structure, picker) {
    let pickers = this.state.pickers
    let index = pickers.findIndex(p => p.id === picker.id)

    if(structure)
      pickers[index].selected = structure.id
    else
      pickers[index].selected = null

    this.setState({ pickers: pickers })
  }

  selected() {
    return this.state.pickers.filter(picker => picker.selected).map(picker => picker.selected)
  }

  render() {
    let pickersPartial

    pickersPartial = this.state.pickers.map((picker) => {
      let remove
      if(this.props.multiple && this.state.pickers.length > 1)
        remove = <a className="LocationPicker__remove pull-right" onClick={ () => this.removePicker(picker) } >
          <i className='glyphicon glyphicon-remove'></i>
        </a>

      return <div key={ picker.id }>
        <SingleLocationPicker
          name={ this.props.name }
          selected={ picker.selected }
          userId={ this.props.userId }
          onChange={ structure => this.handleChange(structure, picker) }
          allowClear={ this.props.allowClear }
          selectLocation={ this.props.selectLocation }
          required={ this.props.required }
        />

        { remove }
      </div>
    })

    if(this.props.multiple)
      pickersPartial.push(<a key="addPicker" onClick={ () => this.addPicker() }>+ Add Location</a>)

    let preview
    if(this.props.preview)
      preview = <LocationPreview
        selected={ this.selected() }
        form={ this.props.preview.form }
        target={ this.props.preview.target }
      />

    return <div className={ this.className() }>
      { pickersPartial }
      { preview }
    </div>
  }
}
