import React from "react"
import PropTypes from "prop-types"
import { HotKeys } from "react-hotkeys"
import scrollIntoViewIfNeeded from "scroll-into-view-if-needed"

import SearchBar from "../SearchBar"
import "./style.scss"

export default class DrillDownMenu extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onBack: PropTypes.func,
    items: PropTypes.array,
    selectedItem: PropTypes.object,
    expandedItem: PropTypes.object,
    itemName: PropTypes.func,
    itemIsExpandable: PropTypes.func
  }

  static defaultProps = {
    onSelect: () => {},
    onBack: () => {},
    items: [],
    itemName: item => item.name,
    itemIsExpandable: item => false
  }

  constructor(props) {
    super(props)

    this.menuItemRefs = []
    this.state = this.setHighlight({ search: null, highlight: 0 }, props)
  }

  componentWillReceiveProps(newProps) {
    if(this.itemsChanged(newProps.items, this.props.items)) {
      let newState = Object.assign({}, this.state, { search: null })
      this.setState(this.setHighlight(newState, newProps), () => {
        this.searchBar.clear()
      })
    }
  }

  itemsChanged(itemsA, itemsB) {
    return !(itemsA.length === itemsB.length && itemsA.every((item, index) => item.id === itemsB[index].id))
  }

  setHighlight(state, props) {
    let highlight = props.expandedItem ? 1 : 0
    return Object.assign({}, state, { highlight: highlight })
  }

  sort(items) {
    return items.sort((a, b) => {
      let nameA = this.props.itemName(a)
      let nameB = this.props.itemName(b)

      if(nameA < nameB)
        return -1
      if(nameA > nameB)
        return 1
      else
        return 0
    })
  }

  // https://codereview.stackexchange.com/questions/153691/escape-user-input-for-use-in-js-regex
  _escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  focusSearch() {
    this.searchBar.focus()
  }

  searchHandler(value) {
    let highlight, menuItems = this.menuItems(value)

    if(menuItems.length === 0)
      highlight = null
    else if(menuItems.length === 1)
      highlight = 0
    else if(menuItems.length > 1)
      highlight = 1

    this.setState({ search: value, highlight: highlight })
  }

  enter() {
    if(this.state.highlight != null) {
      let menuItem = this.menuItems()[this.state.highlight]

      if(menuItem === "Back")
        this.props.onBack()
      else
        this.props.onSelect(menuItem)
    }
  }

  menuItems(search) {
    let items = this.filteredItems(search)

    if(this.props.expandedItem)
      return ["Back", ...items]
    else
      return items 
  }

  moveDown() {
    let maxIndex = this.menuItems().length - 1

    if(this.state.highlight === null)
      this.setState({ highlight: 0 })
    else if(this.state.highlight < maxIndex)
      this.setState({ highlight: this.state.highlight + 1 })

    this.scrollIntoView()
  }

  moveUp() {
    let maxIndex = this.menuItems().length - 1

    if(this.state.highlight === null)
      this.setState({ highlight: maxIndex })
    else if(this.state.highlight > 0)
      this.setState({ highlight: this.state.highlight - 1 })

    this.scrollIntoView()
  }

  scrollIntoView() {
    scrollIntoViewIfNeeded(this.menuItemRefs[this.state.highlight])
  }

  keyHandlers() {
    return {
      up: () => this.moveUp(),
      down: () => this.moveDown(),
      enter: event => {
        this.enter()
        return false
      }
    }
  }

  filteredItems(search) {
    let items = this.sort(this.props.items)
    search = search || this.state.search

    if(search && search !== "")
      items = items.filter(item => {
        return this.props.itemName(item).match(new RegExp(this._escapeRegExp(search), "i"))
      })

    return items 
  }

  menuPartial() {
    return this.menuItems().map((item, index) => {
      if(item === "Back") {
        let buttonClasses ="list-group-item"
        if(index === this.state.highlight)
          buttonClasses += " highlight"

        return <a
          key={ "Back" }
          className={ buttonClasses }
          onClick={ () => this.props.onBack() }
          onMouseEnter={ () => this.setState({ highlight: index }) }
          ref={ (node) => this.menuItemRefs[index] = node }
        >
          Back
        </a>
      } else
        return this.itemPartial(item, index)
    })
  }

  itemPartial(item, index) {
    let classes = "list-group-item"
    let selected = this.props.selectedItem

    if(selected && item.id === selected.id)
      classes = classes + " selected"

    if(this.state.highlight === index)
      classes = classes + " highlight"

    let expandPartial
    if(this.props.itemIsExpandable(item))
      expandPartial = <i className="fas fa-angle-right pull-right"></i>

    return <a
      className={ classes }
      key={ item.id }
      onClick={ () => this.props.onSelect(item) }
      onMouseEnter={ () => this.setState({ highlight: index }) }
      ref={ node => this.menuItemRefs[index] = node }
    >
      { expandPartial }
      { this.props.itemName(item) }
    </a>
  }

  render() {
    return <HotKeys handlers={ this.keyHandlers() }>
      <div className="DrillDownMenu">
        <SearchBar
          ref={ node => this.searchBar = node }
          onChange={ value => this.searchHandler(value) }
        />

        <ul className="DrillDownMenu__items list-group push-down">
          { this.menuPartial() }
        </ul>
      </div>
    </HotKeys>
  }
}
