import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    confirmationMessage: { type: String, default: "Are you sure?" }
  }

  confirmUncheck(event) {
    if ((!this.element.checked) && (!confirm(this.confirmationMessageValue))) {
      event.stopImmediatePropagation()
      this.element.checked = true
    }
  }
}
