import uuid from "uuid/v4"
import Immutable from "../../../lib/Immutable"

export default class Actions {
  static delete(state, index, options) {
    let newChildren = state.children
    let item = state.children[index]
    let deleted = state.deleted
    let end

    if(item.type === "LineItem") {
      deleted = [...deleted, item]
      newChildren = Immutable.remove(newChildren, index)
    } else {
      end = newChildren.findIndex((child, i) => (i > index && child.type === "Section"))

      if(options && options.items) {
        end = (end === -1) ? newChildren.length : end

        deleted = [...deleted, ...newChildren.slice(index + 1, end) ]
        newChildren = Immutable.remove(newChildren, index, end)
      } else if(index === 0 && end !== -1) {
        newChildren = Immutable.remove(newChildren, index)
        let { newArray } = Immutable.moveSection(newChildren, index, end - 1, end)

        newChildren = newArray
      } else
        newChildren = Immutable.remove(newChildren, index)
    }

    return { children: newChildren, deleted: deleted }
  }

  static addSection(state, index) {
    let newChildren, newSection = { key: uuid(), type: "Section" }
    let isFirstSection = !state.children.find(child => child.type === "Section")

    if(isFirstSection)
      index = 0
    else if(!Number.isInteger(index))
      index = state.children.length

    newChildren = Immutable.insert(state.children, index, newSection)

    return { children: newChildren }
  }

  static addLineItem(state, index, { ratingId, weight }={}) {
    let newChildren = state.children
    let newLineItem = {
      name: null,
      key: uuid(),
      type: "LineItem",
      ratingId: ratingId,
      weight: weight || "1"
    }

    if(!(index || index === 0))
      index = newChildren.length

    newChildren = Immutable.insert(newChildren, index, newLineItem)

    return { children: newChildren }
  }

  static update(state, index, props) {
    let newChildren = Immutable.update(state.children, index, props)
    return { children: newChildren }
  }

  static move(state, fromIndex, toIndex, fromOffset) {
    let children = state.children
    let newArray, newIndex
    let target = children[toIndex]

    if(Number.isInteger(fromOffset))
      ({ newIndex, newArray } = Immutable.moveSection(children, fromIndex, fromIndex + fromOffset + 1, toIndex))
    else
      if(target.type === "Section" || fromIndex < toIndex)
        ({ newIndex, newArray } = Immutable.move(children, fromIndex, toIndex + 1))
      else
        ({ newIndex, newArray } = Immutable.move(children, fromIndex, toIndex))

    return { children: newArray, newIndex: newIndex }
  }
}
