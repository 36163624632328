export class AttachmentTracker {
  constructor(initialCount, maxAttachments) {
    this.maxAttachments = maxAttachments;
    this.attachmentsAdded = initialCount || 0;
    this.attachmentsProcessed = 0;
    this.numAttachmentsToProcess = 0;
  }

  tooManyAttachments() {
    if (this.attachmentsAdded >= this.maxAttachments) {
      const message = "No more than " + this.maxAttachments + " files allowed!";
      tbAlert(message, 'danger');
      window.alert(message);
      return true;
    }

    return false;
  }

  setNumAttachmentsToProcess(num) {
    this.numAttachmentsToProcess = num;
  }

  markAttachmentAdded() {
    this.attachmentsAdded += 1;
  }

  markAttachmentProcessed(onComplete) {
    this.attachmentsProcessed += 1;
    if(this.attachmentsProcessed >= this.numAttachmentsToProcess) {
      onComplete();
    }
  }
}

