import React from "react"
import "./ExpandableInput.scss"
import PropTypes from "prop-types"

export default class ExpandableInput extends React.Component {
  static propTypes = {
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  }

  static defaultProps = {
    onOpen: () => {},
    onClose: () => {}
  }

  constructor() {
    super()
    this.state = { active: false }
    this.close = this.close.bind(this)
  }

  open() {
    this.setState({ active: true }, () => {
      document.addEventListener('click', this.close, false)
      this.props.onOpen()
    })
  }

  close(event) {
    if(!event || !this.node.contains(event.target))
      this.setState({ active: false }, () => {
        document.removeEventListener('click', this.close, false)
        this.props.onClose()
      })
  }

  render() {
    let classes = "ExpandableInput"
    if(this.state.active)
      classes = classes + " active"

    return <div ref={ node => this.node = node } onClick={ () => this.open() } className={ classes } >
      <div className="ExpandableInput__inner">
        { this.props.children }
      </div>
    </div>
  }
}
