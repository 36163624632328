import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="realize-template"
export default class extends Controller {
  static targets =[
    "template",
    "control"
  ]

  realize() {
    this.templateTarget.replaceWith(this.templateTarget.content);

    this.controlTargets.forEach((control) => {
      control.style.display = "none";
    });
  }
}
