$(function() {
  // Used on Tickets#show to pass ticket[status] param to controller
  // when a user adds comment and changes status in one action.
  $(".js-comment-and-resolve-ticket").on("click", function(e) {
    $("#js-comment-status").prop( "disabled", false );
  });

  $(".js-mark-status-change").on("click", function(e) {
    // User selected Status
    let status = $(this).data("status");

    // Grab form on page and set status attribute
    let ticketForm = $('#js-ticket-status-change-form');
    $(ticketForm).find('#ticket-status').val(status);

    $(ticketForm).submit();
  });
});
