import React from "react"
import PropTypes from "prop-types"
import ExpandableInput from "../../ExpandableInput/ExpandableInput"
import DrillDownMenu from "../../DrillDownMenu"
import LocationPreview from "../LocationPreview"
import { HotKeys } from "react-hotkeys"
import "./style"

export default class SingleLocationPicker extends React.Component {
  static propTypes = {
    placeholder: PropTypes.string,
    selectLocation: PropTypes.bool,
    selected: PropTypes.number,
    name: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    allowClear: PropTypes.bool
  }

  static defaultProps = {
    placeholder: "Select a location",
    selectLocation: true,
    required: false,
    onChange: () => {},
    allowClear: true
  }

  constructor(props) {
    super(props)

    //
    // selectedStructure:
    //   The current, user-selected structure and displayed at the top. A
    //   null value means that no structure is selected.
    //
    // expandedStructure:
    //   The structure currently being browsed, i.e. the one whose children
    //   are currently displayed in the menu. A null value means that we are
    //   viewing the supervisory ("root") structures for the current user.
    //
    this.state = {
      loading: true,
      structures: [],
      selectedStructure: null,
      expandedStructure: null
    }

    if(this.props.selected)
      this.getStructure(this.props.selected, structure => {
        this.expandStructure(structure.structure_id)
        this.selectStructure(structure)
      })
    else
      this.page(`/api/v4/users/${ this.props.userId }/supervisory_structures.json`, (structures) => {
        if(structures.length === 1 && this.props.selectLocation)
          this.selectStructure(structures[0])
        else
          this.setState({ loading: false, expandedStructure: null, structures: structures })
      })
  }

  loadSupervisoryStructures() {
    this.page(`/api/v4/users/${ this.props.userId }/supervisory_structures.json`, (structures) => {
      this.setState({ loading: false, expandedStructure: null, structures: structures })
    })
  }

  page(uri, callback, structures=[], page=1) {
    $.get(`${ uri }?page=${ page }`, (data) => {
      if(data.structures.length === 0)
        callback(structures)
      else {
        structures = [...structures, ...data.structures]
        this.page(uri, callback, structures, page + 1)
      }
    })
  }

  getStructure(structureId, success, error) {
    $.ajax({
      type: "GET",
      dataType: "json",
      url: `/api/v4/structures/${ structureId }`,
      success: data => success(data.structure),
      error: error
    })
  }

  expandStructure(structureOrStructureId, callback) {
    if(typeof structureOrStructureId === "number")
      this.getStructure(
        structureOrStructureId,
        structure => this.expandStructureChildren(structure, callback),
        () => this.loadSupervisoryStructures()
      )
    else if(structureOrStructureId === null)
      this.loadSupervisoryStructures()
    else
      this.expandStructureChildren(structureOrStructureId, callback)
  }

  expandStructureChildren(structure, callback) {
    this.page(`/api/v4/structures/${ structure.id }/structures`, (structures) => {
      this.setState({ loading: false, expandedStructure: structure, structures: structures, search: null }, callback)
    })
  }

  selectStructure(structure) {
    if(structure.active_children_count > 0)
      this.expandStructure(structure, () => {
        this.setState({ selectedStructure: structure, expandedStructure: structure, loading: false }, () => this.triggerChange())
      })
    else
      this.setState({ selectedStructure: structure, loading: false }, () => this.triggerChange())
  }

  triggerChange() {
    if(this.props.onChange)
      this.props.onChange(this.state.selectedStructure)
  }

  structureName(structure) {
    if(structure.job_number)
      return `(${ structure.job_number }) ${ structure.name }`
    else
      return structure.name
  }

  back() {
    let expanded = this.state.expandedStructure
    if(expanded && expanded.structure_id)
        // If we do not have access to the parent, we assume we are at a supervisory
        // structure and load all supervisory structures to go "back"
        this.getStructure(expanded.structure_id,
          () => this.expandStructure(expanded.structure_id),
          () => this.loadSupervisoryStructures())
    else
      this.loadSupervisoryStructures()
  }

  clear(event) {
    event.stopPropagation()
    this.setState({ selectedStructure: null }, () => {
      this.triggerChange()
      this.loadSupervisoryStructures()
    })
  }

  selectedStructureId() {
    if(this.state.selectedStructure)
      return [ this.state.selectedStructure.id ]
    else
      return []
  }

  render () {
    let inputProps = {
      name: this.props.name,
      required: this.props.required,
      className: "SingleLocationPicker__input"
    }

    if(this.state.selectedStructure)
      inputProps.value = this.state.selectedStructure.id
    else
      inputProps.value = ""

    let selectionPartial
    if(this.state.selectedStructure) {
      let clearPartial
      if(this.props.allowClear)
        clearPartial = <div className="pull-right" onClick={ event => this.clear(event) }>
          <i className="fas fa-times"></i>
        </div>

      selectionPartial = <div>
        <div className="SingleLocationPicker__selection">
          <div className="pull-left">{ this.structureName(this.state.selectedStructure) }</div>
          { clearPartial }
          <div style={{ clear: "both" }} ></div>
        </div>
      </div>
    } else
      selectionPartial = <div>
        <p className="LocationPicker__placeholder">{ this.props.placeholder }</p>
      </div>

    let body
    if(this.state.loading)
      body = <div className="loading-mini"></div>
    else
      body = <div>
        { selectionPartial }
        <DrillDownMenu
          selectedItem={ this.state.selectedStructure }
          expandedItem={ this.state.expandedStructure }
          items={ this.state.structures }
          itemName={ item => this.structureName(item) }
          itemIsExpandable={ item => item.active_children_count > 0 }
          onSelect={ structure => this.selectStructure(structure) }
          onBack={ () => this.back() }
          ref={ node => this.locationSelect = node }
        />
      </div>

    return <HotKeys handlers={{ esc: () => this.expandableInput.close() }}>
      <ExpandableInput
        ref={ (node) => this.expandableInput = node }
        onOpen={ () => this.locationSelect.focusSearch() }
      >
        <div className="SingleLocationPicker" >
          <input type="text" { ...inputProps } />
          <div className="SingleLocationPicker__body">
            { body }
          </div>
        </div>
      </ExpandableInput>
    </HotKeys>
  }
}
