import React from "react"
import PropTypes from "prop-types"
import Structure from "../../lib/Structure"
import Tree from "../Tree"
import { pluralize } from "../../lib/Utilities"
import flat from "array.prototype.flat"
import LocationPreview from "../LocationPicker/LocationPreview"

import "./style.scss"

export default class AccessTree extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    userId: PropTypes.number.isRequired,
    accessibleStructurePaths: PropTypes.array,
    defaultExpand: PropTypes.bool,
    preview: PropTypes.object
  }

  static defaultProps = {
    name: "",
    accessibleStructurePaths: [],
    defaultExpand: true
  }

  constructor(props) {
    super(props)

    this.state = {
      roots: [],
      expanded: false,
      accessibleStructurePaths: this.props.accessibleStructurePaths
    }

    if(this.props.defaultExpand)
      this.expand()
  }

  accessibleStructureIds() {
    return this.state.accessibleStructurePaths.map(path => path[path.length - 1])
  }

  expandedStructureIds() {
    let expandedIds = this.state.accessibleStructurePaths.map(path => path.slice(0, -1))
    return [].concat.apply([], expandedIds)  // Flatten the array of arrays
  }

  expand() {
    Structure.page(`/api/v4/users/${ this.props.userId }/supervisory_structures`, (structures) => {
      let nodes = Structure.sortByName(structures).map(structure => this.createNode(structure))

      this.setState({ expanded: true, roots: nodes }, () => {
        nodes.forEach(node => this.expandAccessibleNode(node))
      })
    })
  }

  expandAccessibleNode(node) {
    if(this.expandedStructureIds().find(id => id === node.site.id))
      Structure.loadChildren(node.site, structures => {
        node.children = structures.map(structure => this.createNode(structure))
        node.expanded = true
        node.childrenLoaded = true
        this.setState({ roots: [ ...this.state.roots ] }, () => {
          node.children.forEach(node => this.expandAccessibleNode(node))
        })
      })
  }

  findNode(node, ancestry) {
    let child, children = this.state.roots

    if(ancestry.length > 0)
      ancestry.forEach(id => children = children.find(child => child.site.id === id).children)

    return children.find(child => child.site.id === node.site.id)
  }

  toggleHandler(node, ancestry) {
    node = this.findNode(node, ancestry)
    node.expanded = !node.expanded

    if(!node.childrenLoaded)
      Structure.loadChildren(node.site, structures => {
        node.children = structures.map(structure => this.createNode(structure))
        node.childrenLoaded = true
        this.setState({ roots: [ ...this.state.roots ] })
      })
    else
      this.setState({ roots: [ ...this.state.roots ] })
  }

  showAllHandler = () => {
    this.setState({showSelected: null})
  }

  showSelectedHandler = () => {
    this.setState({showSelected: true})
  }

  getChildConditionals({site, ancestry}) {
    let accessibleIds = this.accessibleStructureIds()
    let disabled = !!accessibleIds.find(id => ancestry.find(ancestryId => ancestryId === id))
    let checked = !!accessibleIds.find(id => id === site.id)
    let parentOfChecked = !!flat(this.state.accessibleStructurePaths, 1).find(id => id === site.id)
    let visible = !this.state.showSelected || disabled || checked || parentOfChecked
    return { disabled, checked, parentOfChecked, visible }
  }

  renderChild(node, ancestry) {
    let { site, expanded } = node
    let inputId = "AccessTreeNode-" + site.id

    const { disabled, checked, parentOfChecked, visible } = this.getChildConditionals({site, ancestry})

    if(!visible)
      return null;
    
    let toggleClasses = "far"
    if(!expanded && site.active_children_count > 0)
      toggleClasses += " fa-plus-square"
    else if(expanded && site.active_children_count > 0)
      toggleClasses += " fa-minus-square"

    return <p>
      <input
        id={ inputId }
        name={ this.props.name }
        value={ site.id }
        type="checkbox"
        checked={ checked }
        disabled={ disabled }
        onChange={ event => this.checkboxHandler(site, ancestry, event) }
      />
      <label htmlFor={ inputId }>{ Structure.fullName(site) }</label>
      <i className={ toggleClasses } onClick={ () => this.toggleHandler(node, ancestry) } ></i>
    </p>
  }

  checkboxHandler(node, ancestry, event) {
    let index, newPaths = [], accessiblePaths = this.state.accessibleStructurePaths
    ancestry = [ ...ancestry, node.id ]

    accessiblePaths.forEach(path => {
      if(!path.find(id => id === node.id))
        newPaths.push(path)
    })

    if(event.target.checked)
      newPaths.push(ancestry)

    this.setState({ accessibleStructurePaths: newPaths })
  }

  createNode(site) {
    return { site: site, key: site.id, expanded: false, children: [], childrenLoaded: false }
  }

  renderShowButton(handler, boolean, text) {
    return <button onClick={handler} type="button" className={`btn btn-default ${boolean ? 'active' : ''}`}>{text}</button>
  }

  renderTree() {
    let accessibleIds = this.accessibleStructureIds()

    return <div className="AccessTree__tree">
      <div className="AccessTree__tree__selectedAreas">
        <p><b> { accessibleIds.length } { pluralize("Selected Area", accessibleIds.length) } </b></p>
        <div className="btn-group" role="group" aria-label="Show Selected or Show All">
          {this.renderShowButton(this.showAllHandler, !this.state.showSelected, "All")}
          {this.renderShowButton(this.showSelectedHandler, this.state.showSelected, "Show Selected Only")}          
        </div>
      </div>

      <Tree roots={ this.state.roots } renderChild={ (...args) => this.renderChild(...args) } />
    </div>
  }

  renderButton() {
    // We need to always submit the accessible structure ids because
    // the controller is expecting a value
    let accessibleIds = this.accessibleStructureIds()
    let inputs = accessibleIds.map(id => <input key={ id } type="hidden" name={ this.props.name } value={ id } />)

    return <div>
      { inputs }
      <a className="btn btn-default" onClick={ () => this.expand() }>Edit Areas of Access</a>
    </div>
  }

  renderLoading() {
    return <p>Loading areas...</p>
  }

  render() {
    let view = this.state.expanded ? this.renderTree() : (this.props.defaultExpand ? this.renderLoading() : this.renderButton())
    let preview
    if(this.props.preview)
      preview = <LocationPreview
        selected={ this.accessibleStructureIds() }
        form={ this.props.preview.form }
        target={ this.props.preview.target }
      />
    return <div className="AccessTree"> { view } { preview }</div>
  }
}
