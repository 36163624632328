import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleLink", "form"]

  showForm(event) {
    this.formTarget.style.display = "block";
    this.toggleLinkTarget.style.display = "none";
  }

  hideForm(event) {
    this.formTarget.style.display = "none";
    this.toggleLinkTarget.style.display = "block";
  }
}