import React from "react"
import PropTypes from "prop-types"
import "./style.scss"

export default class Menu extends React.Component {
  constructor() {
    super()

    this.state = { active: false }
    this.close = this.close.bind(this)
  }

  toggle(event) {
    event.stopPropagation()

    this.setState({ active: !this.state.active }, () => {
      if(this.state.active)
        document.addEventListener('click', this.close, false)
    })
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.close, false)
  }

  close(event) {
    event.stopPropagation()
    this.setState({ active: false }, () => document.removeEventListener('click', this.close, false))
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.active && !this.state.height && !this.state.width)
      this.setState({ height: this.popup.clientHeight, width: this.popup.clientWidth })
    else if(!this.state.active && this.state.height && this.state.width)
      this.setState({ height: null, width: null })
  }

  render() {
    let style
    if(this.state.height && this.state.width)
      style = { top: -this.state.height/2 + 10, left: -(this.state.width + 20) }

    let popupClasses = this.state.active ? "Menu__popup" : "Menu__popup Menu__popup--hidden"
    let popup = <div
      ref={ node => this.popup = node }
      className={ popupClasses }
      onClick={ event => this.close(event) }
      style={ style }
    >
      { this.props.children }
    </div>

    return <div className="Menu" ref={ node => this.node = node }>
      <i className="fas fa-bars right-margin" onClick={ event => this.toggle(event) } ></i>
      { popup }
    </div>
  }
}
