import React from "react"
import PropTypes from "prop-types"

export default class SearchBar extends React.Component {
  static propTypes = {
    onChange: PropTypes.func
  }

  static defaultProps = {
    onChange: () => {}
  }

  clear() {
    this.input.value = ""
  }

  focus() {
    this.input.focus()
  }

  render() {
    return <div className="SearchBar">
      <div className="input-group input-group-sm">
        <input 
          type="text" 
          className="form-control" 
          onChange={ event => this.props.onChange(event.target.value) }
          ref={ node => this.input = node } 
          tabIndex={ -1 }
        />

        <span className="input-group-addon">
          <i className="fas fa-search"></i>
        </span>
      </div>
    </div>
  }
}
