export function fetchAndPage(uri, query={}, resource, callback, collection=[], page=1) {
  query.page = page
  let queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join("&")

  fetch(`${ uri }?${ queryString }`, { credentials: "same-origin" })
    .then(response => response.json())
    .then(data => {
      if(data[resource].length === 0)
        callback(collection)
      else {
        collection = [...collection, ...data[resource]]
        fetchAndPage(uri, query, resource, callback, collection, page + 1)
      }
    })
}

export function fetchAndPagePromise(uri, query={}, resource, collection=[], page=1) {
  query.page = page
  let queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join("&")

  return fetch(`${ uri }?${ queryString }`, { credentials: "same-origin" })
    .then(response => response.json())
    .then(data => {
      if(data[resource].length === 0)
        return collection
      else {
        collection = [...collection, ...data[resource]]
        return fetchAndPagePromise(uri, query, resource, collection, page + 1)
      }
    })
}

export function sortByString(collection, getValue) {
  return collection.sort((a, b) => {
    let stringA = getValue(a).toLowerCase()
    let stringB = getValue(b).toLowerCase()

    if(stringA < stringB)
      return -1
    if(stringA > stringB)
      return 1
    else
      return 0
  })
}


export function pluralize(word, count) {
  if(count <= 0)
    return word + "s"
  if(count === 1)
    return word
  else
    return word + "s"
}

export class Rest {
  static post(url, data) {
    return fetch(url, {
      body: JSON.stringify(data),
      method: "POST",
      headers: { "Content-Type":"application/json" },
      credentials: "same-origin"
    }).then(response => response.json())
  }

  static delete(url, data={}) {
    return fetch(url, {
      body: JSON.stringify(data),
      method: "DELETE",
      headers: { "Content-Type":"application/json" },
      credentials: "same-origin"
    }).then(response => response.json())
  }
}

export default { Rest, sortByString, fetchAndPage, pluralize }
