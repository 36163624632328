import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["icon", "newArea", "newInspectionFormAssignment", "newChecklistAssignment", "collapse", "node", "link", "trashable"];
  static values = {
    url: String,
    newAreaUrl: String,
    destroyAreaUrl: String,
    createInspectionFormAssignmentUrl: String,
    createChecklistAssignmentUrl: String,
    structureId: Number,
    site: Boolean
  }

  connect() {
    this.expanded = false
    this.selected = false

    if (this.siteValue) {
      this.expand()
      this.loadRightPane()
      this.select()
    }

    window.addEventListener('oqc:unselectAll', (event) => {
      if (event.detail.structureId !== this.structureIdValue) {
        this.unselect()
      }
    });
  }

  // This method toggles the icon when the collapse is expandn or hidden
  collapse(event) {
    $(this.collapseTarget).collapse('hide')
    this.expanded = false
    this.iconTarget.classList.remove("fa-angle-down");
    this.iconTarget.classList.add("fa-angle-right");
  }

  expand(event) {
    $(this.collapseTarget).collapse('show')
    this.expanded = true
    this.iconTarget.classList.remove("fa-angle-right");
    this.iconTarget.classList.add("fa-angle-down");
  }

  selectTrashable(event) {
    this.deselectAllTrashable();
    event.currentTarget.classList.add("active")
  }

  deselectAllTrashable() {
    const structureTrees = this.application.controllers.filter(controller =>
      controller.identifier === this.identifier
    );

    structureTrees.forEach(controller => {
      controller.trashableTargets.forEach(target =>
        target.classList.remove("active")
      )
    }
    )
  }

  select() {
    this.selected = true
    this.nodeTarget.classList.add("active");
    this.linkTarget.style.color = "#fff";
  }

  unselect() {
    this.selected = false
    this.nodeTarget.classList.remove("active");
    this.linkTarget.style.color = "black";
  }

  unselectAll() {
    const evt = new CustomEvent('oqc:unselectAll', {
      detail: {
        structureId: this.structureIdValue
      }
    })
    window.dispatchEvent(evt)
  }

  // primary click handler
  toggleIcon(event) {
    const collapseElement = document.querySelector(event.currentTarget.getAttribute('href'));

    if (this.expanded) {
      if (this.selected) {
        this.collapse();
        this.unselect();
        this.unselectAll()
      } else {
        this.select();
        this.unselectAll();
        this.loadRightPane();
      }
    } else {
      this.expand();
      this.select()
      this.unselectAll()
      this.loadRightPane();
    }
  }

  loadRightPane(event) {
    fetch(this.urlValue)
      .then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        // Replace the content of the contentContainer with the response
        document.querySelector("#right-pane").innerHTML = html;

        // Put the modal at the top level of the document
        var modal = document.getElementById('qr-code-modal');
        var container = document.getElementById('modal-container');
        container.innerHTML = '';
        container.appendChild(modal);
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  keyDownArea(event) {
    // Check if the key pressed is the "Enter" key
    if (event.key === "Enter") {
      // Perform the action you want here
      this.createArea(event);
      // Prevent the form from submitting, if necessary
      event.preventDefault();
    }
  }

  createArea(event) {
    const params = {
      structure: {
        parent_id: this.structureIdValue,
        name: this.newAreaTarget.value
      }
    }
    
    fetch(this.newAreaUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var liElement = document.createElement('li')
        liElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.children')
        ulElement.prepend(liElement)
        this.sortAreas(ulElement)
        this.newAreaTarget.value = ""
        this.newInspectionFormAssignmentTarget.remove()
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
    
  }

  sortAreas(ulElement) {
    const areas = Array.from(ulElement.children)
    areas.sort((a, b) => {
      var aText = a.querySelector('div').dataset.structureTreeStructureNameValue;
      var bText = b.querySelector('div').dataset.structureTreeStructureNameValue;
      return aText.localeCompare(bText, undefined, { sensitivity: 'base' });
    });
    ulElement.innerHTML = "";
    areas.forEach(area => ulElement.appendChild(area));
  }

  sortAssigmnents(ulElement) {
    const assignments = Array.from(ulElement.children)
    assignments.sort((a, b) => {
      var aText = a.querySelector('.assignment-title').innerHTML;
      var bText = b.querySelector('.assignment-title').innerHTML;
      return aText.localeCompare(bText, undefined, { sensitivity: 'base' });
    });
    ulElement.innerHTML = "";
    assignments.forEach(assignment => ulElement.appendChild(assignment));
  }

  createInspectionFormAssignment(event) {
    const formControlElement = this.newInspectionFormAssignmentTarget.querySelector('.form-control')
    const params = {
      inspection_forms_structures: {
        structure_id: this.structureIdValue,
        inspection_form_id: formControlElement.value
      }
    }
    
    fetch(this.createInspectionFormAssignmentUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var liElement = document.createElement('li')
        liElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.inspection-forms')
        ulElement.prepend(liElement)
        this.sortAssigmnents(ulElement)
        this.newAreaTarget.value = ""
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  destroyInspectionFormAssignment(event) {
    const url = event.currentTarget.dataset.destroyInspectionFormAssignmentUrl
    const elementToRemove = event.currentTarget.dataset.elementToRemove

    fetch(url, {      
      method: 'DELETE'
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        document.querySelector(elementToRemove).remove()
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  createChecklistAssignment(event) {
    const formControlElement = this.newChecklistAssignmentTarget.querySelector('.form-control')
    const params = {
      checklist_assignments: {
        structure_id: this.structureIdValue,
        checklist_id: formControlElement.value
      }
    }
    
    fetch(this.createChecklistAssignmentUrlValue, {
      headers: {
        "Content-Type": "application/json",
      },
      method: 'POST',
      body: JSON.stringify(params)
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        var liElement = document.createElement('li')
        liElement.innerHTML = html
        var ulElement = this.element.querySelector('ul.checklists')
        ulElement.prepend(liElement)
        this.sortAssigmnents(ulElement);
        this.newAreaTarget.value = ""
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }

  destroyChecklistAssignment(event) {
    const url = event.currentTarget.dataset.destroyChecklistAssignmentUrl
    const elementToRemove = event.currentTarget.dataset.elementToRemove

    fetch(url, {
      method: 'DELETE'
    }).then(response => response.text()) // Convert the response to text (HTML)
      .then(html => {
        document.querySelector(elementToRemove).remove()
      })
      .catch(error => {
        console.error("Error loading content:", error);
      });
  }
}
