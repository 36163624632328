import React from "react"
import PropTypes from "prop-types"

import Menu from "../Menu"
import Autocomplete from "../../Autocomplete"
import { SectionDND } from "../../../lib/DragAndDrop"

import "./style.scss"

class Section extends React.Component {
  static propTypes = {
    onAdd: PropTypes.func,
    onMove: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    onInsert: PropTypes.func,
    categories: PropTypes.array,
    lineItems: PropTypes.array,
    section: PropTypes.object
  }

  static defaultProps = {
    onAdd: () => {},
    onMove: () => {},
    onDelete: () => {},
    onInsert: () => {},
    onChange: () => {},
    categories: [],
    lineItems: [],
    section: {}
  }

  constructor(props) {
    super(props)
    this.state = { expanded: true }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.section !== this.props.section ||
      nextProps.isDragging !== this.props.isDragging ||
      nextProps.lineItems !== this.props.lineItems ||
      nextState.expanded !== this.state.expanded
  }

  toggle() {
    this.setState({ expanded: !this.state.expanded })
  }

  update(props) {
    let newSection = Object.assign({}, this.props.section, props)
    this.props.onChange(newSection)
  }

  render() {
    let { connectDragSource, connectDropTarget, connectDragPreview, isDragging } = this.props
    let opacity = isDragging ? 0.5 : 1

    let toggleButton, bodyClasses
    if(this.state.expanded) {
      toggleButton = <i className="far fa-minus-square right-margin" onClick={ () => this.toggle() }></i>
      bodyClasses = "Section__body"
    } else {
      toggleButton = <i className="far fa-plus-square right-margin" onClick={ () => this.toggle() }></i>
      bodyClasses = "Section__body Section__body--hidden"
    }

    let header = connectDragPreview(
      connectDropTarget(
        <div className="Section__header row" style={{ opacity: opacity }} >
          <div className="col-md-1">
            {
              connectDragSource(
                <div>
                  <Menu>
                    <ul>
                      <li><a onClick={ () => this.props.onDelete() }>Delete section only</a></li>
                      <li><a onClick={ () => this.props.onDelete({ items: true }) }>Delete section and items</a></li>
                    </ul>
                  </Menu>
                </div>
              )
            }
          </div>

          <div className="col-md-10">
            <Autocomplete
              items={ this.props.categories.map(category => ({ label: category.name })) }
              value={ this.props.section.name }
              onChange={ event => this.update({ name: event.target.value }) }
              onSelect={ value => this.update({ name: value }) }
              placeholder="Section Name"
              minChar={ 3 }
            />
          </div>

          <div className="col-md-1"> <div className="pull-right">{ toggleButton }</div></div>
        </div>
      )
    )

    return <div className="Section" ref={ (node) => this.node = node }>
      { header }
      <div className={ bodyClasses }>
        { this.props.children }
        <a onClick={ () => this.props.onAdd() }>+ Add a Line Item</a>
      </div>
    </div>
  }
}

export default SectionDND(Section)
