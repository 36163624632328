import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["source"];

  copy() {
    const textToCopy = this.sourceTarget.textContent;
    navigator.clipboard.writeText(textToCopy).then(() => {

    }).catch(err => {
      console.error("Failed to copy text: ", err);
    });
  }
}
