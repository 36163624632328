import { AttachmentTracker } from "./AttachmentTracker"

$(function () {
  const attachmentSetting = $(".js-attachment-settings");
  if (attachmentSetting.length == 0) { //we don't have a file attachment setting so bail
    return;
  }

  const moment = require('moment');

  const maxAttachments  = parseInt(attachmentSetting.data("max-attachments"));
  const attachmentCount = parseInt(attachmentSetting.data("attachment-count"));
  let attachmentTracker = new AttachmentTracker(attachmentCount, maxAttachments);

  // Async Attachment Uploads for use in the inspection creation form. Sibling logic to this exists seperately for Tickets due to required differences. See: app-custom16.js.
  $("body").on("click", ".js-remove-attached-file", function (e) {
    e.preventDefault();

    if ($(this).data("attachment-id") !== undefined) {
      $("#" + $(this).data("attachment-id")).val(1);
      $(this).parents(".js-attached-file").hide();
    } else {
      $(this).parents(".js-attached-file").remove();
    }
  });

  function api_v4_url(path) {
    return window.location.protocol + "//" + window.location.hostname + "/api/v4/" + path;
  }

  function setValueIfFieldExists(newFileHtml, field_id, value) {
    const $field = newFileHtml.find(field_id);
    if ($field.length == 1) {
      $field.val(value);
    }
  }

  function preparePresignedPostRequest(data, presignedPost) {
    // NOTE: Set the url and formData for blueimp file upload to unique
    // presigned url per file.
    data.url = presignedPost.url;
    data.formData = [
      {
        "name": "key",
        "value": presignedPost.fields["key"]
      },
      {
        "name": "acl",
        "value": presignedPost.fields["acl"]
      },
      {
        "name": "policy",
        "value": presignedPost.fields["policy"]
      },
      {
        "name": "x-amz-algorithm",
        "value": presignedPost.fields["x-amz-algorithm"]
      },
      {
        "name": "x-amz-credential",
        "value": presignedPost.fields["x-amz-credential"]
      },
      {
        "name": "x-amz-date",
        "value": presignedPost.fields["x-amz-date"]
      },
      {
        "name": "x-amz-signature",
        "value": presignedPost.fields["x-amz-signature"]
      }
    ];
  }

  function sendPresignedPost(data) {
    if (attachmentTracker.tooManyAttachments())
      return;

    attachmentTracker.setNumAttachmentsToProcess(data.originalFiles.length)
    attachmentTracker.markAttachmentAdded();

    var currentFileName = data.files[0].name;
    $.ajax({
      url: api_v4_url('uploads/presigned_posts'),
      data: JSON.stringify({ file_data: [{ name: currentFileName }] }),
      type: "post",
      contentType: 'application/json',
      dataType: "json",
      success: function (response) {
        var presignedPost = response.presignedPosts[currentFileName];
        preparePresignedPostRequest(data, presignedPost);

        data.process().done(function () {
          data.submit();
        });
      },
      error: function (jqXHR, textStatus, errorThrown) {
        var errorMessages = jqXHR.responseJSON.errors.join(", ")
        window.alert("There was an error uploading your attachment. Please try again. File Name:" + currentFileName + ". Error Message: " + errorMessages)
      }
    });
  }

  function postResource(url, params) {
    $.ajax({
      url: url,
      data: JSON.stringify(params),
      type: "post",
      contentType: 'application/json',
      dataType: "json",
      success: function (response) {
        $(".loading").hide();
        attachmentTracker.markAttachmentProcessed(() => window.location.reload());
      }
    });
  }

  function defaultUploadConfig() {
    return {
      type: "POST",
      // NOTE: url and formData are intentionally blank. Should be defined on a
      // per file basis.
      url: "",
      formData: [],
      dataType: "xml",
      // NOTE: Multipart is required to be true in order to use the formData
      // attribute with Blueimp to AWS. var attachmentContainer = $(this).parent(".js-inspection-attachment-form");
      multipart: true,
      sequentialUploads: true,
      autoUpload: true,
      // note: when singlefileupload opt is true, it will process each file
      // individually, and this will always be a 1 element array.
      // if singlefileupload was false, it would try to group per selection.
      // this affects both the add and submit callback logic.
      // AWS S3 will throw an error against a batched submit as it does not allow
      // for bulk uploads. S3 error message: "Upload requires exactly one file"
      singleFileUploads: true,
      replaceFileInput: true,

      add: function (e, data) {
        sendPresignedPost(data);
      },
      start: function () {
        $(".loading").show();
      },
      fail: function (e, data) {
        $(".loading").hide();

        var message = $(data._response.jqXHR.responseXML).find("Error Message").text();
        alert("Attachment Failed: " + message);
      }
    };
  }

  $(".js-attachment-upload-inspection-item-photos").fileupload(
    {
      ...defaultUploadConfig(),
      ...{
        done: function (e, data) {
          const added_by_admin = 3;
          let url = api_v4_url('inspection_item_photos');
          let params = {
            inspection_item_photo: {
              inspection_item_id: $(this).data('inspection-item-id'),
              temporary_url: data.url + "/" + data.formData[0].value,
              source_type: added_by_admin
            }
          };
          postResource(url, params);
        }
      }
    }
  );

  $(".js-attachment-upload-field").fileupload({
    ...defaultUploadConfig(),
    ...{
      done: function (e, data) {
        $(".loading").hide();

        var fileName = data.files[0].name;
        // NOTE: This rebuilds the file's GET URL. I had tried leveraging the
        // object-url we are sending back in the presigned post data, but due to
        // the async behavior of BlueImp this was running into race condition
        // bugs. I tried adjusting the async settings for BlueImp without success.
        // This solution is not clean, but stable. We should revisit when time
        // permits.
        var fileUrl = data.url + "/" + data.formData[0].value;

        var attachmentContainer = $(this).parent(".js-attachment-container");
        var newFileHtmlTemplate = attachmentContainer.find(".js-attached-file").last();
        var newFileHtml = $(newFileHtmlTemplate.clone());

        setValueIfFieldExists(newFileHtml, ".js-attachment-created-at-field", moment().format());
        setValueIfFieldExists(newFileHtml, ".js-attachment-uploaded-at-field", Date.now());
        setValueIfFieldExists(newFileHtml, ".js-attachment-filename-field", fileName);
        setValueIfFieldExists(newFileHtml, ".js-attachment-mime-type-field", data.files[0].type);
        setValueIfFieldExists(newFileHtml, ".js-attachment-byte_size-field", data.files[0].size);
        setValueIfFieldExists(newFileHtml, ".js-attachment-checksum-field", 'not-calculated');
        setValueIfFieldExists(newFileHtml, ".js-attachment-lock-version-field", 0);

        const $temporaryUrlField = newFileHtml.find(".js-attachment-temporary-url-field");
        $temporaryUrlField.val(fileUrl);

        newFileHtml.find(".js-attachment-filename").text(fileName);

        // Display file in UI and enable field to be included in form submission
        newFileHtml.find('input').prop("disabled", false); 
        newFileHtmlTemplate.after(newFileHtml);
        newFileHtml.show();
      }
    }
  });
});
