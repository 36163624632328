import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "resolution", "completedTasksCount" ]
  static classes = [ "success", "failure" ]
  static values = {
    success: String,
    failure: String,
    tasksCompleted: Number
  }

  update(event) {
    let resolved = event.target.checked ? "1" : "0"
    let data = {"task": {"resolved": resolved }}
    let json_data = JSON.stringify(data)
    let updatePath = event.target.dataset.url;

    fetch(updatePath, {
      method: "PATCH",
      body: json_data,
      headers: {
        "Content-Type": "application/json",
      }
    }).then(response => {
      this.animateResponse(response.ok)
      this.updateTaskCount(response.ok, resolved)
    })
  }

  animateResponse(success) {
    let resolution = success ? "success" : "failure"
    let resolution_class = this[resolution + "Class"]
    let resolution_message = this[resolution + "Value"]
    let element = this.resolutionTarget

    element.innerHTML = resolution_message
    element.classList.add(resolution_class)

    setTimeout(() => {
      element.classList.remove(resolution_class)
      element.innerHTML = ""
    }, 1500);
  }

  updateTaskCount(success, resolved) {
    if (success) {
      if (resolved == "1") {
        this.tasksCompletedValue++
      } else {
        this.tasksCompletedValue--
      }

      this.completedTasksCountTarget.innerHTML = this.tasksCompletedValue
    }
  }
}
