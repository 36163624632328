import React from "react";
import PropTypes from "prop-types";

import Fragment from "./Fragment";
import RightPane from "./RightPane";
import Structure from "./store/models/Structure";
import StructureNode from "./StructureNode";
import { fetchInspectionFormsForStructure } from "./store/actions/inspectionFormsActions";
import {
  selectStructure,
  fetchRelationshipsForStructure,
  deleteStructure,
  updateStructure,
} from "./store/actions/structuresActions";

import configureStore from "./store/store";
import { Provider, connect } from "react-redux";

import "./style.scss";

export default class AreaTree extends React.Component {
  static propTypes = {
    structure: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // Manually seed the store
    const topStructure = new Structure(this.props.structure);

    // Add structures to store
    const structuresById = {};
    structuresById[topStructure.id] = topStructure;

    this.store = configureStore({
      structures: {
        state: {
          topStructureId: topStructure.id,
          topStructureIds: [],
        },
        structuresById: structuresById,
      },
    });
  }

  render() {
    return (
      <Provider store={this.store}>
        <AreaTreeConnection />
      </Provider>
    );
  }
}

class AreaTreeBase extends React.Component {
  static propTypes = {
    // Set current state
    structure: PropTypes.object.isRequired, // setup the top level structure
    selectStructure: PropTypes.func.isRequired, // on load, select the top structure

    // Fetches to do on load
    fetchInspectionFormsForStructure: PropTypes.func.isRequired, // assignable forms
    fetchRelationshipsForStructure: PropTypes.func.isRequired, // relationships for top structure

    // Side panel
    selectedStructureId: PropTypes.number, // used to setup the side panel
    deleteStructure: PropTypes.func.isRequired, // handle archives from side panel
    handleRefocusStructure: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.props.selectStructure(this.props.structure.id);
    this.props.fetchRelationshipsForStructure(this.props.structure);
    this.props.fetchInspectionFormsForStructure(this.props.structure.id);

    this.rightPaneUpdateHandler = this.rightPaneUpdateHandler.bind(this);
    this.rightPaneArchiveHandler = this.rightPaneArchiveHandler.bind(this);
  }

  componentDidMount() {
    document.addEventListener(
      "AreaTree:updateArea",
      this.rightPaneUpdateHandler
    );
    document.addEventListener(
      "AreaTree:archiveArea",
      this.rightPaneArchiveHandler
    );
    document.addEventListener("AreaTree:refocus", this.rightPaneArchiveHandler);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "AreaTree:updateArea",
      this.rightPaneUpdateHandler
    );
    document.removeEventListener(
      "AreaTree:archiveArea",
      this.rightPaneArchiveHandler
    );
    document.removeEventListener(
      "AreaTree:refocus",
      this.rightPaneArchiveHandler
    );
  }

  rightPaneUpdateHandler(event) {
    this.props.updateStructure(event.detail.structure);
  }

  rightPaneArchiveHandler(event) {
    this.props.deleteStructure(event.detail.structure.id);
  }

  rightPaneRefocusHandler(event) {
    this.props.handleRefocusStructure(event.detail.structure.id);
  }

  render() {
    return (
      <div className="AreaTree">
        <div className="row">
          <div className="col-xs-7">
            <StructureNode structureId={this.props.structure.id} />
          </div>

          {this.props.selectedStructureId && (
            <div className="AreaTree__rightPane col-xs-5">
              <Fragment
                sourceUri={`/setup/structures/${this.props.selectedStructureId}/right_pane`}
                beforeUpdate={() => RightPane.removeBindings()}
                afterUpdate={() => RightPane.setBindings()}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const topStructureId = state.structures.state.topStructureId;
  const structure = state.structures.structuresById[topStructureId];
  return {
    structure: structure,
    selectedStructureId: state.structures.state.selectedId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // TODO: A lot of these actions could be pre-loaded into the store
    fetchInspectionFormsForStructure: (id) => {
      dispatch(fetchInspectionFormsForStructure(id));
    },
    handleRefocusStructure: (id) => {
      dispatch(setTopLevelStructure(id));
    },
    selectStructure: (id) => {
      dispatch(selectStructure(id));
    },
    fetchRelationshipsForStructure: (structure) => {
      if (
        structure.hasLoadedRelationships === false &&
        structure.isLoadingRelationships === false
      ) {
        dispatch(fetchRelationshipsForStructure(structure));
      }
    },
    deleteStructure: (id) => {
      dispatch(deleteStructure(id));
    },
    updateStructure: (structure) => {
      dispatch(updateStructure(structure));
    },
  };
};

const AreaTreeConnection = connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaTreeBase);
