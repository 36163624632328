import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  selectInspectionFormStructure,
  deleteInspectionFormStructure,
} from "./store/actions/inspectionFormsStructuresActions";

const InspectionFormStructureNodeBase = ({
  inspectionFormStructure,
  isSelected,
  handleInspectionFormStructureClick,
  handleDeleteFormClick,
}) => {
  const id = inspectionFormStructure.id;
  const structureId = inspectionFormStructure.structure_id;

  let deletePartial;
  if (isSelected)
    deletePartial = (
      <span
        className="far fa-trash-alt"
        onClick={() => handleDeleteFormClick(id, structureId)}
      ></span>
    );

  return (
    <div
      inspection-form-structure-id={id}
      inspection-form-id={inspectionFormStructure.inspection_form_id}
    >
      <p className="AreaTree__node__name">
        <span
          className="fal fa-file-alt"
          onClick={() => handleInspectionFormStructureClick(id)}
        ></span>
        <span
          className="left-padding right-margin"
          onClick={() => handleInspectionFormStructureClick(id)}
        >
          {inspectionFormStructure.name}
        </span>
        {deletePartial}
      </p>
    </div>
  );
};

InspectionFormStructureNodeBase.propTypes = {
  inspectionFormStructure: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  handleInspectionFormStructureClick: PropTypes.func.isRequired,
  handleDeleteFormClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let inspectionFormStructureId = ownProps.inspectionFormStructure.id;
  let selectedId = state.inspectionFormsStructures.state.selectedId;
  let isSelected =
    inspectionFormStructureId && selectedId === inspectionFormStructureId;

  return {
    isSelected: isSelected,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleInspectionFormStructureClick: (id) => {
      dispatch(selectInspectionFormStructure(id));
    },
    handleDeleteFormClick: (id, structureId) => {
      dispatch(deleteInspectionFormStructure(id, structureId));
    },
  };
};

const InspectionFormStructureNode = connect(
  mapStateToProps,
  mapDispatchToProps
)(InspectionFormStructureNodeBase);

export default InspectionFormStructureNode;
