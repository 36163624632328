import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "form"]

  connect() {
    this.buttonTarget.addEventListener('click', this.hideModal.bind(this))
  }

  hideModal(event) {
    event.preventDefault()
    $(this.element).modal('hide') // Hide the modal
    this.buttonTarget.removeAttribute('disabled') // Ensure the button is enabled
    this.formTarget.submit()
  }
}