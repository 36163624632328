import React from "react"
import PropTypes from "prop-types"

export default class TreeNode extends React.Component {
  static propTypes = {
    root: PropTypes.object.isRequired,
    ancestry: PropTypes.array.isRequired,
    renderChild: PropTypes.func
  }

  renderChild() {
    if(this.props.renderChild)
      return this.props.renderChild(this.props.root, this.props.ancestry)
    else
      return <p>{ JSON.stringify(this.props.root) }</p>
  }

  renderChildren() {
    let childrenPartial, childrenStyles

    if(!this.props.root.expanded)
      childrenStyles = { display: "none" }

    childrenPartial = this.props.root.children.map(child => {
      return <li key={ child.key }>
        <TreeNode
          root={ child }
          renderChild={ this.props.renderChild }
          ancestry={ [ ...this.props.ancestry, this.props.root.site.id ] }
        />
      </li>
    })

    if(this.props.renderChildren)
      childrenPartial = this.props.renderChildren(childrenPartial)

    return <ul style={ childrenStyles }>{ childrenPartial }</ul>
  }

  render() {
    return <div className="TreeNode">
      { this.renderChild() }
      { this.renderChildren() }
    </div>
  }
}
