import React from "react";
import PropTypes from "prop-types";

import AddStructureInlineForm from "./AddStructureInlineForm";
import AddInspectionFormStructureInlineForm from "./AddInspectionFormStructureInlineForm";

class StructureActions extends React.Component {
  static propTypes = {
    structure: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showInlineForm: false,
      showAddStructureForm: false,
    };
  }

  // Actions
  showAssignFormInlineForm = () => {
    this.setState(() => {
      return {
        ...this.state,
        showInlineForm: true,
        showAddStructureForm: false,
      };
    });
  };

  showAddStructureInlineForm = () => {
    this.setState(() => {
      return {
        ...this.state,
        showInlineForm: true,
        showAddStructureForm: true,
      };
    });
  };

  hideInlineForm = () => {
    this.setState(() => {
      return {
        ...this.state,
        showInlineForm: false,
        showAddStructureForm: false,
      };
    });
  };

  render() {
    const props = {
      parentId: this.props.structure.id,
      handleCancelClick: this.hideInlineForm,
    };

    const show = {
      addStructureForm:
        this.state.showInlineForm && this.state.showAddStructureForm,
      addInspectionForm:
        this.state.showInlineForm && !this.state.showAddStructureForm,
      addAreaForm:
        !this.state.showInlineForm && this.props.structure.hasChildren,
      inspectionFormAction:
        !this.state.showInlineForm &&
        !this.props.structure.hasChildren &&
        this.props.structure.hasInspectionFormsStructures,
      addAreaAndIFAction:
        !this.state.showInlineForm &&
        !this.props.structure.hasChildren &&
        !this.props.structure.hasInspectionFormsStructures,
    };

    const addArea = (
      <li key={`add-area-structure-${this.props.structure.id}`}>
        <p>
          <a onClick={this.showAddStructureInlineForm}>
            <i className="fas fa-plus"></i> Add Area
          </a>
        </p>
      </li>
    );

    const addInspectionForm = (
      <li key={`add-inspection-form-structure-${this.props.structure.id}`}>
        <p>
          <a onClick={this.showAssignFormInlineForm}>
            <i className="fas fa-plus"></i> Assign Inspection Form
          </a>
        </p>
      </li>
    );

    return (
      <div>
        {show.addStructureForm && <AddStructureInlineForm {...props} />}
        {show.addInspectionForm && (
          <AddInspectionFormStructureInlineForm {...props} />
        )}
        {show.addAreaForm && <ul>{addArea}</ul>}
        {show.inspectionFormAction && <ul>{addInspectionForm}</ul>}
        {show.addAreaAndIFAction && <ul>{[addArea, addInspectionForm]}</ul>}
      </div>
    );
  }
}

export default StructureActions;
