import React from "react"
import PropTypes from "prop-types"
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from "react-dnd"
import uuid from "uuid/v4"

import Section from "./Section"
import LineItem from "./LineItem"
import Immutable from "../../lib/Immutable"
import Actions from "./Actions"
import { fetchAndPage } from "../../lib/Utilities"

import "./style.scss"

class SetupInspectionForm extends React.Component {
  static propTypes = {
    ratings: PropTypes.object,
    listItems: PropTypes.array,
    errors: PropTypes.array,
  }

  static defaultProps = {
    ratings: {},
    listItems: [],
    errors: []
  }

  constructor() {
    super()
    this.moveHandler = this.moveHandler.bind(this)

    this.state = {
      children: [],
      loading: true,
      categories: [],
      lineItems: [],
      deleted: []
    }

    fetchAndPage("/api/v4/categories.json", {}, "categories",
      categories => this.setState({ categories: categories }, () => this.loadChildren()))

    fetchAndPage("/api/v4/line_items.json", {}, "line_items",
      line_items => this.setState({ lineItems: line_items }))
  }

  dispatch(action, ...args) {
    this.setState(Actions[action](this.state, ...args))
  }

  loadChildren() {
    let items = []
    let currentSection = { id: null }
    let errors

    this.props.lineItems.forEach((lineItem, index) => {
      if(lineItem.category_id !== currentSection.id) {
        let category = this.state.categories.find(category => category.id === lineItem.category_id)
        currentSection = { key: uuid(), type: "Section", id: category.id, name: category.name }

        items.push(currentSection)
      }

      errors = this.props.errors[index]
      if(errors && Object.keys(errors).length === 0)
        errors = undefined

      items.push({
        id: lineItem.id,
        key: uuid(),
        type: "LineItem",
        name: lineItem.name,
        description: lineItem.description,
        weight: lineItem.weight || "0",
        ratingId: lineItem.rating_id || this.defaultRating().id,
        errors: errors
      })
    })

    this.setState({ children: items, loading: false })
  }

  moveHandler(fromIndex, toIndex, fromOffset) {
    let { children, newIndex } = Actions.move(this.state, fromIndex, toIndex, fromOffset)
    this.setState({ children: children })
    return newIndex
  }

  defaultRating() {
    let groupNames = Object.keys(this.props.ratings.groups)
    return this.props.ratings.groups[groupNames[0]][0]
  }

  validate(event) {
    let invalidSections = this.state.children.filter(child => (child.type === "Section" && !child.name))

    if(invalidSections.length > 0) {
      event.preventDefault()
      alert("Please fill out all categories.")
    }
  }

  childTree() {
    let currentSection, tree = []

    this.state.children.forEach((child, index) => {
      if(child.type === "LineItem") {
        if(currentSection)
          currentSection.lineItemNodes.push({ index: index, lineItem: child })
        else
          tree.push({ index: index, lineItem: child })
      } else {
        currentSection = { index: index, lineItemNodes: [], section: child }
        tree.push(currentSection)
      }
    })

    return tree
  }

  renderLineItem(lineItem, index, deleted=false, nested=false) {
    let name = `inspection_form[inspection_form_items_attributes][${ index }]`
    let style = deleted ? { display: "none" } : {}
    let dispatch = this.dispatch.bind(this)
    let newLineItemProps = { ratingId: lineItem.ratingId, weight: lineItem.weight }
    let classes = "list-group-item"
    classes += lineItem.errors ? " list-group-item-danger" : ""

    return <div className={ classes } key={ lineItem.key } style={ style }>
      <input type="hidden" name={ name + "[id]" } value={ lineItem.id || "" } />
      <input type="hidden" name={ name + "[name]" } value={ lineItem.name || "" } />
      <input type="hidden" name={ name + "[position]" } value={ index } />
      <input type="hidden" name={ name + "[description]" } value={ lineItem.description || "" } />
      <input type="hidden" name={ name + "[weight]" } value={ lineItem.weight || "" } />
      <input type="hidden" name={ name + "[rating_id]" } value={ lineItem.ratingId || "" } />
      <input type="hidden" name={ name + "[_destroy]" } value={ deleted } />

      <LineItem
        key={ lineItem.key }
        index={ index }
        lineItem={ lineItem }
        nested={ nested }
        ratings={ this.props.ratings }
        lineItems={ this.state.lineItems }
        onChange={ props => dispatch("update", index, props) }
        onAdd={ () => dispatch("addLineItem", index + 1, newLineItemProps) }
        onDelete={ () => dispatch("delete", index) }
        onInsert={ () => dispatch("addSection", index) }
        onMove={ this.moveHandler }
      />
    </div>
  }

  newLineItemProps(collection) {
    let lastLineItem = collection.slice().reverse().find(object => object.type === "LineItem")

    if(lastLineItem)
      return { ratingId: lastLineItem.ratingId, weight: lastLineItem.weight }
    else
      return { ratingId: this.defaultRating().id }
  }

  renderSections() {
    let tree = this.childTree()
    let dispatch = this.dispatch.bind(this)

    let sections = tree.map(({ index, section, lineItemNodes, lineItem }) => {
      if(section) {
        let lineItems = lineItemNodes.map(node => node.lineItem)

        return <div key={ section.key }>
          <input type="hidden" name="categories[]" value={ section.name || "" } />
          <Section
            index={ index }
            section={ section }
            lineItems={ lineItems }
            categories={ this.state.categories }
            onAdd={ () => dispatch("addLineItem", lineItemNodes.length + index + 1, this.newLineItemProps(lineItems)) }
            onMove={ this.moveHandler }
            onDelete={ options => dispatch("delete", index, options) }
            onChange={ props => dispatch("update", index, props) }
          >
            <div className="list-group">
              { lineItemNodes.map(node => this.renderLineItem(node.lineItem, node.index, false, true)) }
            </div>
          </Section>
        </div>
      } else
        return this.renderLineItem(lineItem, index)
    })

    let deleted = this.state.deleted.map((lineItem, index) => {
      return this.renderLineItem(lineItem, this.state.children.length + index, true)
    })

    let cancel
    if(this.props.cancelPath)
      cancel = <a className="btn btn-default" href={ this.props.cancelPath }>Cancel</a>

    let lastLineItemB = this.state.children.slice().reverse().find(child => child.type === "LineItem")
    let newLineItemProps
    if(lastLineItemB)
      newLineItemProps = { ratingId: lastLineItemB.ratingId, weight: lastLineItemB.weight }
    else
      newLineItemProps = { ratingId: this.defaultRating().id }

    let addLineItem = <a
      className="btn btn-default"
      onClick={ () => dispatch("addLineItem", undefined, newLineItemProps) }
    >
      <i className="fas fa-plus"></i> Add a Line Item
    </a>

    let bottomActions
    if(!this.state.children.find(child => child.type === "Section"))
      bottomActions = <div className="push-down pull-left"> { addLineItem } </div>

    return <div className="SetupInspectionForm">
      <div className="SetupInspectionForm__actions">
        <div className="pull-left right-margin"> { addLineItem } </div>
        <a className="btn btn-default pull-left" onClick={ () => dispatch("addSection") }>Add Section</a>
        <div style={{ clear: "both" }} />
      </div>

      { sections }
      { deleted }
      { bottomActions }

      <div className='row'>
        <div className='col-xs-12'>
          <div className="form-group push-down pull-left">
            <input
              type="submit"
              name="commit"
              value="Save"
              className="btn btn-primary right-margin"
              onClick={ event => this.validate(event) }
            />
            { cancel }
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    if(this.state.loading)
      return <div className="loading-lg" style={{ height: "35px" }}></div>
    else
      return this.renderSections()
  }
}

export default DragDropContext(HTML5Backend)(SetupInspectionForm)
