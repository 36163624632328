import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    amount: Number
  }

  async connect() {
    await this.#loadGoogleCharts()
    this.#draw()
  }

  async #loadGoogleCharts() {
    const script = document.createElement('script')
    script.src = 'https://www.gstatic.com/charts/loader.js'
    script.id = 'google-charts-loader'

    await new Promise((resolve) => {
      script.addEventListener('load', resolve)
      const existingScript = document.getElementById(script.id)
      if (existingScript) {
        existingScript.addEventListener('load', resolve)
      } else {
        document.head.appendChild(script)
      }

      if (window.google?.charts) {
        resolve()
      }
    })
  }

  #draw() {
    window.google.charts.load('current', { packages: ['corechart'] })
    window.google.charts.setOnLoadCallback(() => {
      (new window.google.visualization.PieChart(this.element)).draw(
        window.google.visualization.arrayToDataTable([
          ['', ''],
          ['', this.amountValue],
          ['', 1 - this.amountValue]
        ]),
        {
          backgroundColor: 'transparent',
          chartArea: { width: '100%', height: '100%', top: 0, left: 0 },
          colors: ['#337ab7', 'transparent'],
          enableInteractivity: false,
          legend: 'none',
          pieSliceBorderColor: 'transparent',
          pieSliceText: 'none'
        }
      )
    })
  }
}
