import React from "react"
import PropTypes from "prop-types"

export default class Fragment extends React.Component {
  static propTypes = {
    sourceUri: PropTypes.string,
    beforeUpdate: PropTypes.func,
    afterUpdate: PropTypes.func
  }

  static defaultProps = {
    beforeUpdate: () => {},
    afterUpdate: () => {}
  }

  constructor(props) {
    super(props)
    this.state = { fragment: "" }
  }

  componentDidMount() {
    this.loadFragment(this.props.sourceUri)
  }

  componentDidUpdate() {
    this.props.afterUpdate()
  }

  componentWillUpdate() {
    this.props.beforeUpdate()
  }

  componentWillReceiveProps(newProps) {
    if(this.props.sourceUri !== newProps.sourceUri)
      this.loadFragment(newProps.sourceUri)
  }

  loadFragment(sourceUri) {
    fetch(sourceUri, { credentials: "same-origin" })
      .then(response => response.text())
      .then(fragment => this.setState({ fragment: fragment }))
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.state.fragment }} />
  }
}
